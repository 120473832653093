<template>
    <div>
        <div ref="chart"></div>
    </div>
</template>

<script>
import Chart from "highcharts"
import Map from "highcharts/modules/map"
import Exporter from "highcharts/modules/exporting"
import CustomEvents from "highcharts-custom-events"

Map(Chart)
Exporter(Chart)
CustomEvents(Chart)

import ChartDefaultOptionsMixin from "@Platon/components/chart/ChartDefaultOptionsMixin"
import MapChartOptionsFormatterMixin from "@Platon/components/chart/MapChartOptionsFormatterMixin"

export default {
    name: "platon-map-chart",
    mixins: [ChartDefaultOptionsMixin, MapChartOptionsFormatterMixin],

    props: {
        options: {
            type: Object
        }
    },

    data() {
        return {
            chart: null
        }
    },

    methods: {
        render() {
            this.chart = Chart.mapChart(this.$refs.chart, this.formatOptions(Chart))
        }
    },

    mounted() {
        this.render()
    }
}
</script>
